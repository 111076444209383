.cell {
  /* width: calc(100% / 8); */
  width: 100%;
  height: 50px;
  padding: 5px 0;
  border-right: 1px solid #CCC;
  border-left: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.time {
  position: absolute;
  top: -11.5px;
  z-index: 2;
  background: #ffffff;
}