.item {
  box-sizing: border-box;
  background: rgba(200,200,200, 1);
  position: absolute;
  cursor: pointer;
  color: #666;
}

.item:hover {
  background: rgba(200,200,200, 0.8);
}

.item.attended {
  background-color: rgba(20, 190, 113, 1);
  color: #FFF;
}

.item.attended:hover {
  background-color: rgba(20, 190, 113, 0.8);
}

.item.cancelled {
  background-color: rgba(232, 71, 84, 1);
  color: #FFF;
}

.item.cancelled:hover {
  background-color: rgba(232, 71, 84, 0.8);
}

.student {
  padding-left: 5px;
  width: 98%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: top;
}

.lesson {
  border-radius: 2px;
  padding: 3px;
  font-size: 10px;
}
