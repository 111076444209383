.wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: white;
  margin-top: -1px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.table {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  /* border-left: 1px solid #CCC; */
  border-top: 1px solid #CCC;
}

.items {
  position: absolute;
  display: flex;
  width: calc(100% / 8);
}

.item {
  margin: 0 1px;
  width: 25%;
  box-sizing: border-box;
  background: green;
}