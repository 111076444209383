.cell {
  /* width: calc(100% / 8); */
  width: 100%;
  height: 50px;
  padding: 5px 0;
  border-right: 1px solid #CCC;
  border-bottom: 1px solid #CCC;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}