.monthWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.weekWrappper {
  height: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.item {
  width: calc(100% / 8);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #999;
}
