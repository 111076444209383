.detailWrapper{
  display: flex;
  flex-direction: row;
  font-size: 14px;
  color: '#444';
  padding-bottom: 10px;
  padding-top: 10px;
}

.detailLabel{
  width: 120px;
  font-weight: bold;
  text-align: right;
  padding-right: 15px;
}

.detailData{
  font-weight: 400;
  color: 'rgba(0, 0, 0, 0.65)';
}

